<template>
    <LiefengContent>
        <template v-slot:title>学习答题活动</template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable :talbeColumns="tableColumns" :tableData="menuList" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
            </div>

            <LiefengModal :title="currentFormData.topicId ? '修改' : '新增'" width="30%" height="350px" :value="addModal.status" @input="addModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="主题" prop="topicName">
                            <Input placeholder="请输入主题" v-model.trim="currentFormData.topicName"></Input>
                        </FormItem>
                        <FormItem label="每题限时" prop="timeLimit">
                            <Input style="width: 100px" type="Number" v-model.trim="currentFormData.timeLimit" placeholder="填写时间" maxlength="50"></Input>
                            <span style="margin-left: 10px">秒</span>
                        </FormItem>
                        <FormItem label="备注说明" prop="topicDesc">
                            <Input type="textarea" v-model.trim="currentFormData.topicDesc" placeholder="多行输入" maxlength="500"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="submit">保存</Button>
                </template>
            </LiefengModal>
            <!-- 题目设置 -->
            <LiefengModal :value="setModal.status" @input="setModalData" :title="'题目设置(包含内容设置和问题设置，一个内容可以设置多个问题)'" :fullscreen="true">
                <template v-slot:contentarea>
                    <SetModal @success="setSuccess" ref="setModal" v-if="setModal.status" :questionList="questionVoList"></SetModal>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="setModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="setSubmit">保存</Button>
                </template>
            </LiefengModal>
            <!-- 抽奖设置 -->
            <LiefengModal :value="priceModal.status" @input="priceModalData" :title="'抽奖设置(包含规则设置和奖项设置)'" :fullscreen="false" width="500px" height="500px">
                <template v-slot:contentarea>
                    <PriceModal @success="setPrice" ref="priceModal" v-if="priceModal.status" :priceData="priceData"></PriceModal>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="priceModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="priceSubmit">保存</Button>
                </template>
            </LiefengModal>

            <!-- 二维码 -->
            <LiefengModal :value="qrcodeModal.status" @input="qrcodeModalData" :title="'二维码'" :fullscreen="false" width="250px">
                <template v-slot:contentarea>
                    <div id="qrcode_id" style="width: 100%; height: 100%"></div>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="qrcodeModalData(false)">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
import SetModal from "./children/setModal"
import PriceModal from "./children/priceModal"
import QRCode from "qrcodejs2"
import proxy from "@/api/proxy.js"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUpload,
        SetModal,
        PriceModal,
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    title: "主题",
                    width: 250,
                    key: "topicName",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: { color: "blue", cursor: "pointer" },
                                on: {
                                    click: () => {
                                        this.currentFormData = {
                                            scenicId: params.row.scenicId,
                                            seq: params.row.seq,
                                            timeLimit: params.row.timeLimit,
                                            topicDesc: params.row.topicDesc,
                                            topicId: params.row.topicId,
                                            topicName: params.row.topicName,
                                        }
                                        this.addModalData(true)
                                    },
                                },
                            },
                            params.row.topicName
                        )
                    },
                },
                {
                    title: "答题时间限制",
                    minWidth: 200,
                    key: "timeLimit",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.timeLimit + "秒")
                    },
                },
                {
                    title: "创建人",
                    minWidth: 200,
                    key: "creatorName",
                    align: "center",
                },
                {
                    title: "创建时间",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? (params.row.gmtCreate = this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss")) : "")
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 500,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.currentFormData = {
                                                    scenicId: params.row.scenicId,
                                                    seq: params.row.seq,
                                                    timeLimit: params.row.timeLimit,
                                                    topicDesc: params.row.topicDesc,
                                                    topicId: params.row.topicId,
                                                    topicName: params.row.topicName,
                                                }
                                                this.addModalData(true)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.questionVoList = []
                                                this.topicId = params.row.topicId
                                                await this.$get("/gateway/api/syscenic/pc/question/getQuestionByTopicId", {
                                                    topicId: this.topicId,
                                                }).then(res => {
                                                    if (res.data && res.code == 200) {
                                                        this.questionVoList = res.data.questionVoList
                                                        if (res.data.topicId && res.data.topicId != "") {
                                                            this.type = "edit"
                                                        } else {
                                                            this.type = "add"
                                                        }
                                                    }
                                                    this.setModalData(true)
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "题目设置" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.topicId = params.row.topicId
                                                this.priceData = {}
                                                await this.$get("/gateway/api/syscenic/pc/question/getRuleByTopicId", {
                                                    topicId: this.topicId,
                                                }).then(res => {
                                                    this.priceData = res.data
                                                    this.priceModalData(true)
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "抽奖设置" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "答题记录",
                                                    content: `/page#/taskpackgeindexrecord?id=${params.row.topicId}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "答题记录" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "评分统计",
                                                    content: `/page#/statisticalanswer?topicId=${params.row.topicId}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "评分统计" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "中奖名单",
                                                    content: `/page#/taskpackgeindexprice?id=${params.row.topicId}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "中奖名单" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.qrcodeModalData(true)
                                                var text =
                                                    `${proxy["/custom"].target}/#/apps/suiyue/pages/humanity/scoring?topicId=` +
                                                    params.row.topicId +
                                                    "&topicName=" +
                                                    encodeURIComponent(params.row.topicName)

                                                if (!this.qrcode) {
                                                    this.qrcode = new QRCode(document.getElementById("qrcode_id"), {
                                                        text: encodeURIComponent(text),
                                                        width: 230, // 高度
                                                        height: 230, // 宽度
                                                        colorDark: "#000000", // 前景色
                                                        colorLight: "#ffffff", // 后景色
                                                        correctLevel: QRCode.CorrectLevel.H, // 容错级别
                                                    })
                                                } else {
                                                    this.qrcode.makeCode(text)
                                                }
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "二维码" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.deleteData(params.row.topicId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "删除" //1001
                                ),
                            ]
                        )
                    },
                },
            ],
            currentFormData: {},
            validateForm: {
                topicName: [{ required: true, message: "请输入主题", trigger: "blur" }],
                timeLimit: [{ required: true, message: "请输入每条限时", trigger: "blur" }],
                topicDesc: [{ required: true, message: "请输入备注说明", trigger: "blur" }],
            },

            addModal: {
                status: false,
            },
            setModal: {
                status: false,
            },
            priceModal: {
                status: false,
            },
            qrcodeModal: {
                status: false,
            },

            questionVoList: null,

            topicId: "",
            type: "add",
            qrcode: null,
        }
    },
    methods: {
        // 获取奖品模态框数据
        setPrice(val) {
            if (val) {
                this.$post(
                    "/gateway/api/syscenic/pc/question/createOrUpdateActivity",
                    {
                        ...val,
                        topicId: this.topicId,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "操作成功",
                            background: true,
                        })
                        this.priceModalData(false)
                        return
                    }
                })
            }
        },
        // 奖品设置确定接口
        priceSubmit() {
            this.$refs.priceModal.sumbit()
        },
        addModalData(status) {
            this.addModal.status = status
        },
        setModalData(status) {
            this.setModal.status = status
        },
        priceModalData(status) {
            this.priceModal.status = status
        },
        qrcodeModalData(status) {
            this.qrcodeModal.status = status
        },
        // 题目设置保存按钮
        setSubmit() {
            this.$refs.setModal.sumbit()
        },
        setSuccess(val) {
            console.log("val;", val)
            if (val) {
                val.map(item => {
                    if (item.extra && item.extra.length) {
                        item.extra = item.extra[0].url
                    } else {
                        item.extra = ""
                    }
                    if (item.voiceFile && item.voiceFile.length) {
                        item.voiceFile = item.voiceFile[0].url
                    } else {
                        item.voiceFile = ""
                    }
                    if (item.answerVoList && item.answerVoList.length) {
                        item.answerVoList.map((items, indexs) => {
                            items.seq = indexs
                            if (items.optionVoList && items.optionVoList) {
                                items.optionVoList.map((itemss, indexss) => {
                                    itemss.seq = indexss
                                })
                            }
                        })
                    }
                })
            }
            if (val) {
                let url = ""
                if (this.type == "add") {
                    url = "/gateway/api/syscenic/pc/question/addQuestion"
                } else {
                    url = "/gateway/api/syscenic/pc/question/updateQuestion"
                }
                this.$post(
                    url,
                    {
                        questionVoList: val,
                        topicId: this.topicId,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "操作成功",
                            background: true,
                        })
                        this.setModalData(false)
                        return
                    }
                })
            }
        },

        submit() {
            this.$refs.form.validate(status => {
                if (status) {
                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    this.$post(
                        "/gateway/api/syscenic/pc/question/createOrUpdate",
                        {
                            scenicId: "1",
                            ...cFormData,
                        },
                        { "content-type": "application/json" }
                    )
                        .then(res => {
                            this.addModalData(false)
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                this.currentFormData = {}
                                this.getSceneData(this.page)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },

        append(data) {
            this.addModalData(true)
            this.currentFormData = {
                id: data.id,
                topicName: "",
                timeLimit: "",
                topicDesc: "",
            }
        },
        deleteData(topicId) {
            this.$Modal.confirm({
                title: "删除选项",
                content: "删除后无法恢复，是否继续删除？",
                okText: "确认删除",
                cancelText: "取消",
                onOk: () => {
                    this.$post("/gateway/api/syscenic/pc/question/deleteTopicById", {
                        topicId,
                    }).then(res => {
                        var that = this
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            that.getSceneData(this.page)
                            return
                        }
                    })
                },
            })
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.getSceneData(obj.page)
        },
        getSceneData(page) {
            this.loading = true
            this.$get("/gateway/api/syscenic/pc/question/listQuestionTopicAll", {
                page: page,
                size: 10000,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                        this.page = res.currentPage
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
    },
    async created() {
        this.getSceneData(1)
    },
}
</script>

<style lang='less'>
/deep/.ivu-form-item-content {
    display: flex !important;
}
.ivu-form-item-label {
    display: flex !important;
}
</style>
